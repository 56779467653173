import { useContext, createContext, useState, useEffect } from "react";
import { Asset, TradingPair, useAce } from "@archax/web-lib";
import { getEnv } from "@ace/env";
import { localStorageKeys } from "../../constants";
import { LocalStorage } from "../../utils/local-storage";

interface SelectedTradingPairData {
  tradingPair: TradingPair | null;
  baseAsset: Asset | null;
  quoteAsset: Asset | null;
}

interface SelectedTradingPairContext {
  tradingPair: TradingPair | null;
  quoteAsset: Asset | null;
  baseAsset: Asset | null;
  updateSelectedTradingPair: (
    tradingPairId: number,
    options?: { dontPersist?: boolean },
  ) => void;
}

export const SelectedTradingPairContext =
  createContext<SelectedTradingPairContext>({} as SelectedTradingPairContext);

interface SelectedTradingPairProviderProps {
  children: React.ReactNode;
}

export const getInitialTradingPair = (
  tradingPairs: TradingPair[],
): TradingPair => {
  const lastSelectedTradingPair =
    LocalStorage.getItem(localStorageKeys.lastTradingPairSelected) || null;
  const DEFAULT_TRADING_PAIR = getEnv("VITE_ACE_WEB_DEFAULT_TRADING_PAIR");

  return (
    tradingPairs.find((tp) => String(tp.id) === lastSelectedTradingPair) ||
    tradingPairs.find((tp) => String(tp.id) === DEFAULT_TRADING_PAIR) ||
    tradingPairs[0]
  );
};

const initTradingPairDataState = {
  tradingPair: null,
  baseAsset: null,
  quoteAsset: null,
};

const SelectedTradingPairProvider = ({
  children,
}: SelectedTradingPairProviderProps) => {
  const { data, dataReady } = useAce({ topics: ["trading-pairs", "assets"] });
  const [selectedTradingPairData, setSelectedTradingPairData] =
    useState<SelectedTradingPairData>(initTradingPairDataState);

  const tradingPairs: TradingPair[] = Object.values(
    data["trading-pairs"] || [],
  );

  const getAsset = (id: number) => {
    const assets = Object.values(data["assets"]) as Asset[];
    return assets.find((asset) => asset.id === id) || null;
  };

  const updateSelectedTradingPair = (
    tradingPairId: number,
    options?: { dontPersist?: boolean },
  ) => {
    const newTradingPair: TradingPair = data["trading-pairs"][tradingPairId];
    const newTradingPairData = {
      tradingPair: newTradingPair,
      baseAsset: getAsset(newTradingPair.baseAssetId),
      quoteAsset: getAsset(newTradingPair.quoteAssetId),
    };

    setSelectedTradingPairData(newTradingPairData);

    if (options?.dontPersist) return;
    LocalStorage.setItem(
      localStorageKeys.lastTradingPairSelected,
      String(newTradingPair.id),
    );
  };

  useEffect(() => {
    if (!selectedTradingPairData.tradingPair && dataReady) {
      const initialTradingPair = getInitialTradingPair(tradingPairs);
      if (initialTradingPair) {
        updateSelectedTradingPair(initialTradingPair.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReady]);

  return (
    <SelectedTradingPairContext.Provider
      value={{
        tradingPair: selectedTradingPairData.tradingPair,
        quoteAsset: selectedTradingPairData.quoteAsset,
        baseAsset: selectedTradingPairData.baseAsset,
        updateSelectedTradingPair,
      }}
    >
      {children}
    </SelectedTradingPairContext.Provider>
  );
};

const useSelectedTradingPair = () => {
  const { tradingPair, baseAsset, quoteAsset, updateSelectedTradingPair } =
    useContext(SelectedTradingPairContext);

  return {
    tradingPair,
    baseAsset,
    quoteAsset,
    updateSelectedTradingPair,
  };
};

export { SelectedTradingPairProvider, useSelectedTradingPair };
