import { useMemo, type FC, type ReactNode, useState } from "react";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";

type TabbedCardProps = {
  children?: ReactNode | ((selectedTab: number) => ReactNode);
  panels?: ReadonlyArray<ReactNode>;
  tabs: ReadonlyArray<string>;
  mobile?: boolean;
};

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    "&": { paddingTop: 0 },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  [theme.breakpoints.up("md")]: {
    "&": { paddingTop: "16px" },
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
}));

const toDashedCase = (str: string) => str.toLowerCase().split(" ").join("-");

export const TabbedCard: FC<TabbedCardProps> = ({
  children,
  panels,
  tabs,
  mobile,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (_event: React.SyntheticEvent, tab: number) => {
    setSelectedTab(tab);
  };

  const tabsElements = useMemo(
    () =>
      tabs.map((label, index) => (
        <Tab
          data-cy={`${toDashedCase(label)}-tab`}
          key={label}
          label={label}
          value={index}
        />
      )),
    [tabs],
  );

  const resolvedChildren = useMemo(() => {
    if (panels) return panels[selectedTab];
    return typeof children === "function" ? children(selectedTab) : children;
  }, [panels, children, selectedTab]);

  return (
    <Card
      sx={{
        paddingTop: "15px",
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Tabs value={selectedTab} sx={{ px: 3 }} onChange={handleTabClick}>
        {tabsElements}
      </Tabs>
      <Divider />

      <CardContentStyled
        data-cy={`${toDashedCase(tabs[selectedTab])}`}
        sx={mobile ? { padding: 0 } : undefined}
      >
        {resolvedChildren}
      </CardContentStyled>
    </Card>
  );
};
