import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { Seo } from "../../components/seo";
import {
  Document,
  openFileBlob,
  useAceAccountManagement,
} from "@archax/web-lib";
import {
  Button,
  Card,
  Chip,
  Divider,
  Link,
  Skeleton,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Download from "@mui/icons-material/Download";
import {
  getSignedContracts,
  SignedContract,
} from "../../services/get-signed-contracts";
import { useEffect, useState } from "react";
import { useDialogContext } from "../../ace-widgets/dialog-provider";
import { UnregulatedMasterAgreementPopup } from "../../components/account-management/popups/unregulated-master-agreement-popup";
import { useRouter } from "../../hooks/use-router";
import { paths } from "../../paths";
import toast from "react-hot-toast";
import { Toast } from "../../components/toast";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { signContract } from "../../services/sign-contract";
import { getEnv } from "@ace/env";
import { getDocuments } from "../../services/get-documents";
import { success } from "../../theme/colors";

const ACE_AUTH_SERVER = getEnv("VITE_ACE_WEB_AUTH_SERVER");

const UNREGULATED_CONTRACT_CATEGORY = "unregulated-contract";

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

const UnregulatedContracts = () => {
  const { currentToken } = useAceAccountManagement();
  const { openWithContent, closeModal } = useDialogContext();
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [signedContracts, setSignedContracts] = useState<
    SignedContract[] | null
  >(null);
  const { push } = useRouter();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const fetchSignedContracts = async () => {
    try {
      const response = await getSignedContracts();
      setSignedContracts(response);
    } catch (error) {
      toast(
        () => (
          <Toast
            type="error"
            title={(error as Error).message}
            data-cy="unregulated-contracts-fetch-signed-contracts-error-toast"
          />
        ),
        {
          duration: 10000,
        },
      );
    }
  };

  const loadContracts = async () => {
    setLoadingDocuments(true);
    const documents = await getDocuments(currentToken);
    const unregulatedContracts = documents.filter(
      (c) => c.category === UNREGULATED_CONTRACT_CATEGORY,
    );
    setDocuments(unregulatedContracts);
    setLoadingDocuments(false);
  };

  useEffect(() => {
    loadContracts();
    fetchSignedContracts();
  }, []);

  const handleSignContract = async (contractKey: string) => {
    closeModal();
    try {
      const response = (await signContract(contractKey)) as any;
      console.log(response);
      await fetchSignedContracts();
      push(paths.accountOverview);
      toast(
        () => (
          <Toast
            type="success"
            onClick={() => toast.dismiss()}
            title={"Contract signed successfully!"}
            data-cy="unregulated-contracts-sign-contract-success-toast"
          />
        ),
        {
          duration: 10000,
        },
      );
    } catch (error) {
      toast(
        () => (
          <Toast
            type="error"
            onClick={() => toast.dismiss()}
            title={(error as Error).message}
            data-cy="unregulated-contracts-sign-contract-error-toast"
          />
        ),
        {
          duration: 10000,
        },
      );
    }
  };

  const handleBackToAccount = () => {
    closeModal();
    push(paths.accountOverview);
  };

  const handleSignAgreement = (contractKey: string) => {
    openWithContent(
      <UnregulatedMasterAgreementPopup
        onLeave={handleBackToAccount}
        onConfirm={() => handleSignContract(contractKey)}
      />,
    );
  };

  const handleViewFile = (uri: string) => {
    openFileBlob(currentToken, "/documents/" + uri);
  };

  // const hasSignedAllUnregulatedContracts =
  //   signedContracts &&
  //   documents &&
  //   documents
  //     .filter((doc) => doc.category === UNREGULATED_CONTRACT_CATEGORY)
  //     .every((doc) => signedContracts.find((c) => c.documentKey === doc.key));

  const isSigned = (key: string) => {
    return !!signedContracts?.find((c) => c.documentKey === key);
  };

  const renderUnregulatedContracts = () => {
    const unregulatedContracts = documents
      ? documents.filter(
          (doc) => doc.category === UNREGULATED_CONTRACT_CATEGORY,
        )
      : [];

    return unregulatedContracts.map((contract) => (
      <Stack spacing={3} key={contract.key}>
        <Card
          sx={{
            p: 3,
          }}
        >
          <Stack
            py={1}
            px={1}
            sx={{
              border: "1px solid",
              borderColor: isSigned(contract.key) ? success.main : "divider",
              borderRadius: "8px",
            }}
          >
            <Stack direction="row" justifyContent="space-between" py={4} px={2}>
              <Typography fontWeight={"bold"}>{contract.label}</Typography>
              <Link
                href={`${ACE_AUTH_SERVER}/documents/${contract.filename}?jwt=${currentToken}`}
                target="_blank"
                download
              >
                <Download color="action" />
              </Link>
            </Stack>
            <Divider variant="middle" />
            <Stack
              direction={smUp ? "row" : "column-reverse"}
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              py={2}
              px={2}
            >
              <Typography
                variant={smUp ? "subtitle2" : "caption"}
                color="text.secondary"
              >
                {`Last Updated: ${formatDate(contract.updated)}`}
              </Typography>
              {isSigned(contract.key) ? (
                <Chip
                  label="SIGNED"
                  sx={{
                    backgroundColor: `${success.main}1F`, // 1F is 12% opacity
                    color: success.dark,
                  }}
                />
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleViewFile(contract.filename)}
                  endIcon={<OpenInNewIcon />}
                  data-cy="unregulated-contracts-view-master-agreement-button"
                >
                  View
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
        <Stack direction="row" justifyContent={"end"}>
          {isSigned(contract.key) ? (
            <Button
              variant="contained"
              endIcon={<ChevronRight />}
              onClick={() => push(paths.accountOverview)}
              data-cy="back-to-account-button"
            >
              Back to account
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!contract.label.toLocaleLowerCase().includes("master")}
              endIcon={<ChevronRight />}
              onClick={() => handleSignAgreement(contract.key)}
              data-cy="unregulated-contracts-sign-button"
            >
              Sign
            </Button>
          )}
        </Stack>
      </Stack>
    ));
  };

  return (
    <>
      <Seo />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container data-cy="unregulated-contracts-page" maxWidth={"xl"}>
          <Stack p={smUp ? 5 : 1} spacing={3} justifyContent="space-between">
            <Typography data-cy="page-title" variant={smUp ? "h4" : "h6"}>
              Crypto and other unregulated products master agreement
            </Typography>
            <Stack spacing={3}>
              {loadingDocuments ? (
                <span data-cy="unregulated-contracts-skeleton">
                  <Skeleton height={200} sx={{ transform: "scale(1)" }} />
                  <Skeleton height={75} width={100} sx={{ alignSelf: "end" }} />
                  <Skeleton height={200} sx={{ transform: "scale(1)" }} />
                  <Skeleton height={75} width={100} sx={{ alignSelf: "end" }} />
                </span>
              ) : (
                renderUnregulatedContracts()
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export { UnregulatedContracts };
