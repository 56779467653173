import {
  AceAccountManagementProvider,
  AceProvider,
  hasTheseRolesOnlyInAnyOrg,
} from "@archax/web-lib";
import { FC, Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { lazyWithPreload } from "react-lazy-with-preload";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getEnv } from "@ace/env";
import { RiskHeader } from "./components/risk-header";
import { RTL } from "./components/rtl";
import { Toaster } from "./components/toaster";
import { Toast } from "./components/toast";
import { ReconnectionOverlay } from "./components/reconnection-overlay/";
import { SettingsConsumer, SettingsProvider } from "./contexts/settings";
import { useNprogress } from "./hooks/use-nprogress";
import { createTheme } from "./theme";
import { AnalyticsBanner } from "./analytics-banner";
import { paths } from "./paths";

// import { SettingsButton } from "./components/settings/settings-button";
import { SettingsDrawer } from "./components/settings/settings-drawer";
import { SelectedTradingPairProvider } from "./hooks/ace/selected-trading-pair-provider";
import { DialogProvider } from "./ace-widgets/dialog-provider";
import { Layout } from "./layouts/dashboard";

import "./global.css";
// Remove if locales are not used
import "./locales/i18n";
import { CssBaseline } from "@mui/material";
// import AccountOverview from "./pages/account-management/account-overview";

import { loadPosthog } from "./load-posthog";
import { loadGoogleAnalytics } from "./load-google-analytics";
import { loadRecaptcha } from "./load-recaptcha";
import { UnregulatedContracts } from "./pages/account-management/unregulated-contracts";
import { DocumentLibrary } from "./pages/account-management/document-library";
import {
  ONBOARDING_USER_ROLES,
  localStorageKeys,
  sessionStorageKeys,
} from "./constants";
import { errorBoundary } from "./components/error-boundary";
import { LocalStorage } from "./utils/local-storage";

// UNAUTHORIZED PAGES
const UnauthorisedLayout = lazyWithPreload(
  () => import("./layouts/unauthorised/layout"),
);

const Register = lazyWithPreload(() => import("./pages/register"));
const Login = lazyWithPreload(() => import("./pages/login/."));
const RegistrationConfirmation = lazyWithPreload(
  () => import("./pages/registration-confirmation"),
);
const ResetPassword = lazyWithPreload(() => import("./pages/reset-password"));
const SetPassword = lazyWithPreload(() => import("./pages/set-password"));

// AUTHORIZED PAGES
const SimplifiedView = lazyWithPreload(
  () => import("./pages/trade-dashboard/simplified-view"),
);
const TradeDashboard = lazyWithPreload(() => import("./pages/trade-dashboard"));
const FundingStatusPage = lazyWithPreload(
  () => import("./pages/trade-dashboard/funding-status"),
);
const CustodyPage = lazyWithPreload(
  () => import("./pages/trade-dashboard/custody"),
);
const TransactionsPage = lazyWithPreload(
  () => import("./pages/trade-dashboard/transactions"),
);
const OnboardingWarning = lazyWithPreload(
  () => import("./pages/account-management/onboarding-warning"),
);

// ONBOARDING PAGES
const SetupMFA = lazyWithPreload(
  () => import("./pages/account-management/setup-mfa"),
);
const CountrySelection = lazyWithPreload(
  () => import("./pages/account-management/country-selection"),
);
const AccountTypeSelection = lazyWithPreload(
  () => import("./pages/account-management/account-type-selection"),
);

const AccountOverview = lazyWithPreload(
  () => import("./pages/account-management/account-overview"),
);

const AmlKyc = lazyWithPreload(
  () => import("./pages/account-management/aml-kyc"),
);

const AppropriatenessTest = lazyWithPreload(
  () => import("./pages/account-management/appropriateness-test"),
);

const ClientCategorisation = lazyWithPreload(
  () => import("./pages/account-management/client-categorisation"),
);

const RestrictedInvestor = lazyWithPreload(
  () => import("./pages/account-management/restricted-investor"),
);

const Error404 = lazyWithPreload(() => import("./pages/404"));

const postHogKey = getEnv("VITE_ACE_WEB_POST_HOG_PROJECT_API_KEY");

export const App: FC = () => {
  // DEV NOTE: TEMPORARILY REMOVING PRELOADS
  // useEffect(() => {
  // // AUTHORIZED
  // TradeDashboard.preload();
  // FundingStatusPage.preload();
  // CustodyPage.preload();
  // TransactionsPage.preload();
  // // ONBOARDING
  // SetupMFA.preload();
  // CountrySelection.preload();
  // AccountTypeSelection.preload();
  // AccountOverview.preload();
  // OnboardingWarning.preload();
  // AppropriatenessTest.preload();
  // ClientCategorisation.preload();
  // RestrictedInvestor.preload();
  // // UNAUTHORIZED
  // Login.preload();
  // Register.preload();
  // ResetPassword.preload();
  // RegistrationConfirmation.preload();
  // SetPassword.preload();
  // Error404.preload();
  // }, []);

  const [token, setToken] = useState(
    window.sessionStorage.getItem(sessionStorageKeys.token) || "",
  );
  const [showAnalyticsBanner, setShowAnalyticsBanner] = useState(false);
  const navigate = useNavigate();

  const syncTokenAcrossTabs = (event: StorageEvent) => {
    if (event.key === sessionStorageKeys.token) {
      setToken(event.newValue || "");
    }
  };

  const handleModuleLoadError = (error: any) => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      window.location.reload();
    }
  };

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    const isLogout = params.get("logout");

    if (isLogout) {
      window.sessionStorage.removeItem(sessionStorageKeys.token);
      setToken("");

      // remove timeout from blacklisted country users
      const highestId = window.setTimeout(() => {
        for (let i = highestId; i >= 0; i--) {
          window.clearTimeout(i);
        }
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    checkAnalytics();
    window.addEventListener("storage", syncTokenAcrossTabs);
    window.addEventListener("error", handleModuleLoadError);

    return () => {
      window.removeEventListener("storage", syncTokenAcrossTabs);
      window.removeEventListener("error", handleModuleLoadError);
    };
  }, []);

  const checkAnalytics = () => {
    const acceptedAnalytics = LocalStorage.getItem(
      localStorageKeys.acceptedAnalytics,
    );

    if (acceptedAnalytics && acceptedAnalytics !== "false") {
      loadRecaptcha();
      loadPosthog({ postHogKey });
      loadGoogleAnalytics();
    } else {
      setShowAnalyticsBanner(true);
    }
  };

  const setAndStoreToken = (initialToken: string) => {
    window.sessionStorage.setItem(sessionStorageKeys.token, initialToken);
    setToken(initialToken);
  };

  const handleError = (message: string, error?: any) => {
    let displayError =
      "There was a problem trying to connect. Try again later or contact support if issue persists.";

    if (navigator.onLine === false) {
      displayError =
        "No internet connection detected. Please check your connection and try again.";
    }

    toast(() => <Toast type="error" title={displayError} />);

    window.sessionStorage.removeItem(sessionStorageKeys.token);
    navigate(paths.logout);
  };

  useNprogress();

  const UnAuthorisedRoutes = (
    <Route
      element={
        <UnauthorisedLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </UnauthorisedLayout>
      }
    >
      <Route
        path={paths.login}
        element={<Login setToken={setAndStoreToken} />}
      />
      <>
        <Route path={paths.register} element={<Register />} />
        <Route
          path={paths.setupMfa}
          element={<SetupMFA setToken={setAndStoreToken} />}
        />
        <Route path={paths.resetPassword} element={<ResetPassword />} />
        <Route path={paths.setPassword} element={<SetPassword />} />
      </>
      <Route
        path={paths.confirmRegistration}
        element={<RegistrationConfirmation />}
      />
    </Route>
  );

  const AuthorisedRoutes = (
    <Route
      element={
        <SettingsConsumer>
          {(settings) => {
            return (
              <>
                <Layout>
                  <Outlet />
                </Layout>
                {/* <SettingsButton onClick={settings.handleDrawerOpen} /> */}
                <SettingsDrawer
                  canReset={settings.isCustom}
                  onClose={settings.handleDrawerClose}
                  onReset={settings.handleReset}
                  onUpdate={settings.handleUpdate}
                  open={settings.openDrawer}
                  values={{
                    colorPreset: settings.colorPreset,
                    contrast: settings.contrast,
                    direction: settings.direction,
                    paletteMode: settings.paletteMode,
                    responsiveFontSizes: settings.responsiveFontSizes,
                    stretch: settings.stretch,
                    layout: settings.layout,
                    navColor: settings.navColor,
                  }}
                />
              </>
            );
          }}
        </SettingsConsumer>
      }
    >
      {/** Trade Dashboard */}
      <Route
        element={
          token ? (
            hasTheseRolesOnlyInAnyOrg(ONBOARDING_USER_ROLES, token) ? (
              <OnboardingWarning />
            ) : (
              <Suspense>
                <AceProvider
                  token={token}
                  onError={handleError}
                  autoRenewalStorageKey={sessionStorageKeys.token}
                  aceAuthWebSocketUrl={getEnv(
                    "VITE_ACE_WEB_AUTH_WEBSOCKET_SERVER",
                  )}
                >
                  <DialogProvider>
                    <ReconnectionOverlay>
                      <SelectedTradingPairProvider>
                        <Outlet />
                      </SelectedTradingPairProvider>
                    </ReconnectionOverlay>
                  </DialogProvider>
                </AceProvider>
              </Suspense>
            )
          ) : (
            <Navigate to={paths.login} />
          )
        }
      >
        <Route
          path={paths.simplifiedView}
          element={errorBoundary(<SimplifiedView />)}
        />
        <Route
          path={paths.tradeDashboard}
          element={errorBoundary(<TradeDashboard />)}
        />
        <Route path={paths.custody} element={<CustodyPage />} />
        <Route path={paths.transactions} element={<TransactionsPage />} />
        <Route path={paths.fundingStatus} element={<FundingStatusPage />} />
      </Route>

      {/** Account Management */}
      <Route
        path={paths.account}
        element={
          token ? (
            <AceAccountManagementProvider
              token={token}
              onError={handleError}
              autoRenewalStorageKey={sessionStorageKeys.token}
              aceAuthRestUrl={getEnv("VITE_ACE_WEB_AUTH_SERVER")}
            >
              <DialogProvider>
                <Outlet />
              </DialogProvider>
            </AceAccountManagementProvider>
          ) : (
            <Navigate to={paths.login} />
          )
        }
      >
        <Route path={paths.countrySelection} element={<CountrySelection />} />
        <Route
          path={paths.accountTypeSelection}
          element={<AccountTypeSelection />}
        />
        <Route path={paths.accountOverview} element={<AccountOverview />} />
        <Route path={paths.amlkyc} element={<AmlKyc />} />
        <Route
          path={paths.appropriatenessTest}
          element={<AppropriatenessTest />}
        />
        <Route
          path={paths.clientCategorisation}
          element={<ClientCategorisation />}
        />
        <Route
          path={paths.restrictedInvestor}
          element={<RestrictedInvestor />}
        />
        <Route
          path={paths.finPromCategorisation}
          element={<div>FinProm Categorisation</div>}
        />
        <Route
          path={paths.regulatedContracts}
          element={<div>Regulated Contracts</div>}
        />
        <Route
          path={paths.unregulatedContracts}
          element={<UnregulatedContracts />}
        />
        <Route path={paths.documentLibrary} element={<DocumentLibrary />} />
      </Route>
      {token && <Route path="*" element={<Error404 token={token} />} />}
    </Route>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <SettingsConsumer>
          {(settings) => {
            // Prevent theme flicker when restoring custom settings from browser storage
            if (!settings.isInitialized) {
              // return null;
            }

            const theme = createTheme({
              colorPreset: settings.colorPreset,
              contrast: settings.contrast,
              direction: settings.direction,
              paletteMode: settings.paletteMode,
              responsiveFontSizes: settings.responsiveFontSizes,
            });

            return (
              <ThemeProvider theme={theme}>
                <Helmet>
                  <meta name="color-scheme" content={settings.paletteMode} />
                  <meta
                    name="theme-color"
                    content={theme.palette.neutral[900]}
                  />
                </Helmet>
                <RTL direction={settings.direction}>
                  <RiskHeader />
                  <CssBaseline />
                  <Routes>
                    {UnAuthorisedRoutes}
                    {AuthorisedRoutes}
                    <Route path="*" element={<Error404 token={token} />} />
                  </Routes>
                  {showAnalyticsBanner && (
                    <AnalyticsBanner
                      onAccepted={() => {
                        const consentTimestamp = new Date().toLocaleString();
                        LocalStorage.setItem(
                          localStorageKeys.acceptedAnalytics,
                          consentTimestamp,
                        );
                        setShowAnalyticsBanner(false);
                        loadPosthog({ postHogKey, consentTimestamp });
                        loadGoogleAnalytics();
                      }}
                    />
                  )}
                  <Toaster />
                </RTL>
              </ThemeProvider>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </LocalizationProvider>
  );
};
