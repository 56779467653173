import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { paths } from "../../paths";

import { SimplifiedView } from "../../svgs/simplified-view";
import { Trade } from "../../svgs/trade";
import { Custody } from "../../svgs/custody";

import { Help } from "../../svgs/help";
import { Account } from "../../svgs/account";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: "Buy & Sell",
            path: paths.simplifiedView,
            icon: <SimplifiedView />,
          },
          {
            title: "Advanced Trading",
            path: paths.tradeDashboard,
            icon: <Trade />,
          },
          {
            title: "Custody",
            icon: <Custody />,
            path: paths.custody,
            items: [
              {
                title: "Transactions",
                label: "transactions-drawer",
                path: paths.transactions,
              },
              {
                title: "Funding Status",
                label: "funding-status-drawer",
                path: paths.fundingStatus,
              },
            ],
          },
        ],
      },
    ];
  }, [t]);
};

export const footerSection: Section[] = [
  {
    items: [
      {
        title: "Account",
        icon: <Account />,
        label: "overview-nav",
        path: paths.accountOverview,
      },
      {
        title: "Document Library",
        icon: <AutoStoriesOutlinedIcon />,
        label: "document-library-nav",
        path: paths.documentLibrary,
      },
      {
        title: "Help",
        path: "mailto:support@archax.com",
        external: true,
        icon: <Help />,
      },
    ],
  },
];
