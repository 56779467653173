import { alpha } from "@mui/system/colorManipulator";
import { backdropClasses } from "@mui/material/Backdrop";
import { filledInputClasses } from "@mui/material/FilledInput";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { paperClasses } from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { common } from "@mui/material/colors";
import type { Components } from "@mui/material/styles/components";
import type {
  PaletteColor,
  PaletteOptions,
} from "@mui/material/styles/createPalette";
import { error } from "../colors";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: palette.text?.primary,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: (palette.info as PaletteColor).darkest,
        },
        standardWarning: {
          backgroundColor: (palette.warning as PaletteColor).darkest,
        },
        standardError: {
          backgroundColor: (palette.error as PaletteColor).darkest,
          color: palette.text?.primary,
          "& .MuiAlert-icon": {
            color: (palette.error as PaletteColor).light,
          },
        },
        standardSuccess: {
          backgroundColor: (palette.success as PaletteColor).darkest,
          color: palette.text?.primary,
          "& .MuiAlert-icon": {
            color: (palette.success as PaletteColor).main,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          borderColor: alpha("#6C737F", 0.5),
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "8px",
        },
        option: {
          "&:hover": {
            backgroundColor: `${alpha("#2196f3", 0.24)} !important`,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.neutral![300],
          color: common.black,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: alpha(common.black, 0.5),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              "0px 5px 22px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.06)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action!.active,
        },
        root: {
          borderColor: palette.neutral![700],
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: (palette.primary as PaletteColor).main,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: palette.text!.secondary,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: palette.divider,
          "&:hover": {
            backgroundColor: palette.action!.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
            borderColor: (palette.primary as PaletteColor).main,
            boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`,
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
            boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: (palette.primary as PaletteColor).light,
          textDecoration: `underline ${
            (palette.primary as PaletteColor).light
          }`,
          "&:hover": {
            color: palette.text?.primary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.action!.hover,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.divider,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: (palette.primary as PaletteColor).main,
              borderWidth: "3px",
            },
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: (palette.error as PaletteColor).main,
              borderWidth: "3px",
            },
          },
        },
        notchedOutline: {
          borderColor: palette.divider,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: palette.neutral![500],
        },
        track: {
          backgroundColor: palette.neutral![400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: palette.neutral![800],
            color: palette.neutral![400],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 30px ${palette.neutral![800]} inset`,
          },
        },
      },
    },
    // @ts-expect-error - keeping in line with MUI types mocing target
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: palette.divider,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backdropFilter: "blur(6px)",
          background: alpha(palette.neutral![900], 0.8),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: palette.text?.secondary,
          },
          "&.Mui-error": {
            color: error.main,
          },
        },
      },
    },
  };
};
