import { getEnv } from "@ace/env";
import { sessionStorageKeys } from "../constants";

export type SignedContract = {
  contractId: number | string;
  dateAccepted: string;
  documentKey: string;
  signingHash: string;
};

export const MASTER_CONTRACT_DOCUMENT_KEY = "unregulated-master-agreement";

export const getSignedContracts = async (): Promise<SignedContract[]> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${window.sessionStorage.getItem(
      sessionStorageKeys.token,
    )}`,
  };

  const response = await fetch(
    `${getEnv("VITE_ACE_WEB_AUTH_SERVER")}/contracts/check-signed`,
    {
      method: "GET",
      headers,
    },
  );
  const json = await response.json();

  if (json.status === "ERROR") {
    throw new Error(json.error[0].msg);
  }

  const data = json.data as SignedContract[];

  return data;
};
